.TheCard {
  padding: var(--spacing-unit);
  text-align: center;
  background-color: white;
  --card-image-height: auto;
}

.TheCard.\--clickable {
  cursor: pointer;
}

.\--tiny {
  --card-image-height: 80px;
}

.\--small {
  --card-image-height: 100px;
}

.TheCard__Image {
  height: var(--card-image-height);
  background-color: white;
}

.TheCard__Image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center center;
     object-position: center center;
}

.TheCard__Body {
  margin-top: var(--spacing-unit);
}