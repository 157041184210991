.Breadcrumb {

}

.Breadcrumb_Item {

}

.Breadcrumb_Item:nth-child(1n + 2):before {
    content: "/";
    display: inline-block;
    margin: 0 0.25rem;
}