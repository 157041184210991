.AssistantComment {
  position: relative;
  /* --background-color: #eee; */
  --background-color: #fff1ac;
  --assistant-comment-content-display: none;
}

.AssistantComment__AvatarContainer {
  position: absolute;
  right: 0;
  bottom: 100%;
  padding-bottom: var(--spacing-sm);

  cursor: pointer;
}

.AssistantComment__AvatarContainer:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 50%;
  -webkit-transform: translateX(50%);
      -ms-transform: translateX(50%);
          transform: translateX(50%);
  border: var(--spacing-unit) solid transparent;
  border-bottom-color: var(--background-color);
  display: var(--assistant-comment-content-display);
}

.AssistantComment__Content {
  background-color: var(--background-color);
  padding: var(--spacing-sm);
  display: var(--assistant-comment-content-display);
}

.\--open {
  --assistant-comment-content-display: block;
}