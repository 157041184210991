.EventContestsOfType {}

:root {
  --spacing-contest-price: 0.5rem;
}

.EventContestsOfType_Item {
  border: 1px solid #ddd;
  background-color: #f6f6f6;
  padding: var(--spacing-xs);
}

.EventContestsOfType_Item+.EventContestsOfType_Item {
  margin-top: var(--spacing-sm);
}

.EventContestPrices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: calc(-0.5 * var(--spacing-contest-price));
}

.EventContestPrices_ItemWrapper {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding: calc(0.5 * var(--spacing-contest-price));
}

@media (min-width: 600px) {
  .EventContestPrices_ItemWrapper {
    -ms-flex-preferred-size: calc(100%/3);
        flex-basis: calc(100%/3);
  }
}

.EventContestPrices_Item {
  padding: var(--spacing-sm) var(--spacing-xs);
  border: 1px solid #ddd;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  cursor: pointer;
}

.EventContestPrices_Item.-is-selected.-is-valid {
  background-color: white;
  border-color: #ccc;
}

.EventContestPrices_Item_Icon {}

.EventContestPrices_Item_Icon {
  height: 100px;
}

.EventContestPrices_Item_Description {
  margin-top: auto;
}

.EventContestPrices_Item_Actions {
  margin-top: auto;
}