.Messages {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    text-align: center;
}

.Messages_Item {
    
    position: relative;

    display: inline-block;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: white;

    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .4);

            box-shadow: 0 2px 5px rgba(0, 0, 0, .4);

    -webkit-transition: opacity .5s, -webkit-transform .25s;

    transition: opacity .5s, -webkit-transform .25s;

    -o-transition: opacity .5s, transform .25s;

    transition: opacity .5s, transform .25s;

    transition: opacity .5s, transform .25s, -webkit-transform .25s;
}

.Message_Item_Animation-enter, .Message_Item_Animation-leave, .Message_Item_Animation-leave-active {
    opacity: 0;
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
}