.PriceBundlesList {}

.PriceBundlesList__Item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: var(--spacing-unit) 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.PriceBundlesList__Item+.PriceBundlesList__Item {
  border-top: none;
}

.PriceBundlesList__Item>div:nth-child(1) {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 80px;
          flex: 0 0 80px;
}

.PriceBundlesList__Item>div:nth-child(2) {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}